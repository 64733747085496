

import Vue from "vue";
import Component from "vue-class-component";
import Center from "@/AsicJungleAnalyticsUI/components/composites/Center.vue";
import LoadingIcon from "@/AsicJungleAnalyticsUI/components/content/LoadingIcon.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";

@Component({
    components: {Stack, TextContent, LoadingIcon, Center}
})
export default class LoadingScreen extends Vue {
}
