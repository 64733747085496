
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import BaseInput from "@/AsicJungleAnalyticsUI/components/Form/BaseInput.vue";
import Cluster from "@/AsicJungleAnalyticsUI/components/composites/Cluster.vue";
import Badge from "@/AsicJungleAnalyticsUI/components/content/Badge.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";

/**
 * Represents a message providing additional information regarding the value in the input.
 * This can be used to display validation errors.
 */
export interface Hint {
    /**
     * Allows specifying a context value for the display of the message such as "danger", "warning, "info" etc.
     */
    context: string
    message: string
}

/**
 * Base component for text input. This component can be used as-is or extended for advanced use-cases.
 */
@Component({
    components: {Button, Badge, Cluster, BaseInput, TextContent, Stack, Box}
})
export default class MultiInput extends BaseInput {
    @Prop({type: String, required: false, default: ""})
    private placeholder: string

    /**
     * Callback used to determine if the content typed by the user should be considered a value.
     * If the callback returns false, the value will not be added to the list and the input will remain in the same state.
     * @private
     */
    @Prop({required: false, default: null})
    private validateValue: (v: string) => boolean

    @Prop({required: false, default: true})
    private allowDuplicates: boolean

    @Prop({type: Array})
    private value: string[]

    private internalValues: string[] = []

    private content = ""

    created(): void {
        this.internalValues = this.value.slice()
    }

    addValue(v: string): void {
        this.internalValues.push(this.content)
        this.$emit("input", this.internalValues)
        this.$emit("added", v)
    }

    removeValueAtIndex(index: number): void {
        const removedValue = this.internalValues.splice(index, 1)[0]
        this.$emit("removed", removedValue)
    }

    onInput(e): void {
        this.content = e.target.value
    }

    onCommit(): void {
        if(this.content === "") return
        if (this.validateValue){
            if (!this.validateValue(this.content)) {
                this.$emit("validation-failed", this.content)
                return
            }
        }
        this.addValue(this.content)
        this.content = ""
    }

}
