
import Vue from "vue";
import Component from "vue-class-component";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";

@Component({
    components: {Stack}
})
export default class Form extends Vue {

}
