
import {Component, Prop} from "vue-property-decorator";
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";

/**
 * Represents a Button
 */
@Component
export default class Button extends ContextAwareComponent {

    /**
     * Indicates if the button is disabled or not.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly disabled: boolean

    /**
     * Indicates if this button should have a solid background.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly solid: boolean;

    /**
     * Indicates if this button should have a gradient background.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly gradient: boolean;

    /**
     * Indicates if this button should have an outline background.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly outline: boolean;

    /**
     * Indicates if a button should be displayed as a link, meaning it has no border or background.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly link: boolean;

    /**
     * Indicates if a button should be a circle.
     * The value should be a scale unit for the size.
     * @private
     */
    @Prop({type: String, default: ""})
    private readonly circle: string

    /**
     * Indicates if a button should be a circle.
     * The value should be a scale unit for the size.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly cta: boolean

    get classNames(): Record<string, boolean> {
        return {
            'btn' : true,
            'btn--primary': this.primary,
            'btn--secondary': this.secondary,
            'btn--tertiary': this.tertiary,

            'btn--info': this.info,
            'btn--success': this.success,
            'btn--warning': this.warning,
            'btn--danger': this.danger,

            'btn--solid': this.solid,
            'btn--gradient': this.gradient,
            'btn--outline': this.outline,
            'btn--link': this.link,

            'btn--circle': this.circle !== "",
            [`btn--circle-unit${this.circle}`]: this.circle !== "",

            'btn--cta': this.cta,
        }
    }

    handleClick(e: Event): void {
        this.$emit('click', e)
    }
}
