
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import BaseInput from "@/AsicJungleAnalyticsUI/components/Form/BaseInput.vue";

/**
 * Base component for text input. This component can be used as-is or extended for advanced use-cases.
 */
@Component({
    components: {BaseInput, TextContent, Stack, Box}
})
export default class Input extends BaseInput {
    @Prop({type: String, required: true})
    private readonly type: string

    @Prop({type: String, required: false, default: ""})
    private readonly placeholder: string

    @Prop({type: String})
    private readonly value: string

    @Prop({type: Boolean, required: false, default: false})
    private readonly required: boolean

    handleInput(e): void {
        this.$emit("input", e.target.value)
    }
}
