
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

/**
 * The grid composite allows displaying boxes side by side and to wrap them into rows when they overflow.
 * The grid has a limitation that requires to define the minimum width so
 * that it can know when to wrap elements.
 */
@Component
export default class Grid extends Vue {
    @Prop({type: String, default: "350px"})
    private readonly minWidth: string

    get style(): Record<string, string> {
        return {
            "grid-template-columns": `repeat(auto-fit, minmax(min(${this.minWidth}, 100%), 1fr))`
        }
    }
}
