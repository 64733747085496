import {ApiClient, ApiResponse} from "@/Core/ApiClient";
import {DataPoint} from "@/Classification/models/DataPoint";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import {DataSource} from "@/Classification/models/DataSource";
import ClassifiedDataPoint from "@/Classification/models/ClassifiedDataPoint";

class ClassificationApi {
    classifyDataSource(dataSourceId: string, dataPoints: DataPoint[]): Promise<ApiResponse<null>> {
        return ApiClient.post<null>({
            url: "/data-sources/classify",
            payload: {
                dataSourceId,
                dataPoints,
            }
        })
    }

    discardDataSource(dataSourceId: string): Promise<ApiResponse<null>> {
        return ApiClient.post<null>({
            url: "/data-sources/discard",
            payload: {
                dataSourceId,
            }
        })
    }

    getLabels(): Promise<ApiResponse<DataPointLabel[]>> {
        return ApiClient.get<DataPointLabel[]>({
            url: "/labels",
            payload: {}
        })
    }

    getDataSources(): Promise<ApiResponse<DataSource[]>> {
        return ApiClient.get<DataSource[]>({
            url: "/data-sources",
            payload: {}
        })
    }

    getDataPoints(): Promise<ApiResponse<ClassifiedDataPoint[]>> {
        return ApiClient.get<ClassifiedDataPoint[]>({
            url: "/data-points",
            payload: {}
        })
    }

    requestReclassification(dataPointId: string): Promise<ApiResponse<null>> {
        return ApiClient.post<null>({
            url: "/data-points/request-reclassification",
            payload: {
                dataPointId,
            }
        })
    }

    exportDataPointsAsCsv(): Promise<ApiResponse<null>> {
        return ApiClient.get<null>({
            url: "/data-points/export",
            payload: {}
        })
    }
}

const api = new ClassificationApi()
export default api