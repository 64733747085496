
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";

/**
 * Represents a Widget in the application.
 */
@Component({
    components: {Stack, Box}
})
export default class Widget extends Vue {

    /**
     * Represents the background of the widget. See @Box for more information.
     * @private
     */
    @Prop({type: String, default: "low"})
    private readonly background: string
}
