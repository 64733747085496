
import {Component, Prop} from "vue-property-decorator";
import {BaseComponent} from "@/AsicJungleAnalyticsUI/components/Component";

/**
 * Box is the basic container for content.
 *
 * The width and the height of a box should in most cases be determined by the parent of the box or its content.
 * This is what allows composites to correctly represent different types of boxes as a single unit.
 */
@Component({})
export default class Box extends BaseComponent {
    /**
     * Allows to use a custom HTML element instead of a div.
     * @private
     */
    @Prop({type: String, default: "div"})
    private readonly as: string

    /**
     * In the case where one uses the `element` attribute, allows
     * defining the attributes that should be put on the element.
     * @private
     */
    @Prop({type: Object, default: () => ({})})
    private readonly attrs: Record<string, unknown>

    /**
     * Defines the level of contrast to be used as the boxes background.
     * The value used here will also generate a class name on the element as such
     * box--{background} e.g. box--base
     * @private
     */
    @Prop({type: String, default: "none"})
    private readonly background: "base"|"none"|"low"|"medium"|"high"|'primary'|'secondary'|'tertiary'|'info'|'success'|'warning'|'danger'

    /**
     * The level of padding based on the sizing scale.
     * @private
     */
    @Prop({default: "none"})
    private readonly padding: string

    get classNames(): Record<string, boolean> {
        return {
            'box': true,
            [`box--bg-${this.background}`]: this.background !== "none",
            [`box--padding${this.padding}`]: this.padding !== "none",
        }
    }

    handleClick(e: Event): void {
      this.$emit('click', e)
    }
}
