
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";
import Component from "vue-class-component";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";

@Component({
    components: {Box}
})
export default class Notice extends ContextAwareComponent {
    get className(): Record<string, boolean> {
        return {
            'notice': true,
            'notice--primary': this.primary,
            'notice--secondary': this.secondary,
            'notice--tertiary': this.tertiary,
            'notice--info': this.info,
            'notice--warning': this.warning,
            'notice--success': this.success,
            'notice--danger': this.danger,
        }
    }
}
