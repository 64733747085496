
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

/**
 * The switcher composite allows to position boxes side by side and
 * to wrap them when they overflow. As opposed to the Cluster, when they wrap
 * they will take the full space available.
 */
@Component
export default class Switcher extends Vue {
    /**
     * CSS value of justify-content property.
     * @private
     */
    @Prop({type: String, default: "center"})
    private readonly justify: string

    /**
     * Level in the unit scale for the space between elements in the switcher.
     * @private
     */
    @Prop({type: String, default: "none"})
    private readonly spaceUnit: string

    get classNames() {
        return {
            'switcher': true,
            [`switcher--space-unit${this.spaceUnit}`]: this.spaceUnit !== "none",
            [`switcher--justify-${this.justify}`]: true,
        }
    }
}
