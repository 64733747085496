

import Vue from "vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Component from "vue-class-component";

@Component({
    components: {Box}
})
export default class ContentArea extends Vue {
}
