
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

/**
 * Composite grouping boxes vertically while allowing space between them.
 * [It relies on the CSS lobotomized owl selector](https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/).
 */
@Component
export default class Stack extends Vue {
    /**
     * The CSS margin to apply between elements of the stack as per the unit scale.
     * This attributes adds a class `stack--space-{space}`
     * @private
     */
    @Prop({type: String, default: "0"})
    private spaceUnit: string
    /**
     * CSS value of justify-content property.
     * @private
     */
    @Prop({type: String, default: "start"})
    private readonly justify: string

    get classNames(): Record<string, boolean> {
        return {
            'stack': true,
            [`stack--space-unit${this.spaceUnit}`]: this.spaceUnit !== "none",
            [`stack--justify-${this.justify}`]: true,
        }
    }
}
