
import {Component, Prop} from "vue-property-decorator";
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";

@Component({components: {}})
export default class LoadingIcon extends ContextAwareComponent {
    @Prop({type: String, default: ""})
    private readonly size: string

    get classNames(): Record<string, boolean> {
        return {
            'loading-icon' : true,
            'loading-icon--primary': this.primary,
            'loading-icon--secondary': this.secondary,
            'loading-icon--tertiary': this.tertiary,
            'loading-icon--info': this.info,
            'loading-icon--success': this.success,
            'loading-icon--warning': this.warning,
            'loading-icon--danger': this.danger,
            ['loading-icon--size' + this.size]: this.size !== ""
        }
    }
}
