
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class Icon extends Vue {
    @Prop({type: String})
    private readonly name: string

    @Prop({type: String, default: "svg"})
    private readonly extension: string

    @Prop({type: Boolean, default: false})
    private readonly noMargin: boolean;

    @Prop({type: String, default: ""})
    private readonly size: string

    /**
     * Indicates if the colors of the images should be adapted to support the dark mode.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private ignoreTheme: boolean;

    get iconUrl(): string {
        let iconName = this.name
        if (!this.ignoreTheme) {
            const baseName = this.name.replace("-primary", "").replace("-white", "")
            const color = this.$store.getters["theme"] === "dark" ? "white" : "primary";
            iconName = `${baseName}-${color}`
        }

        return require(`../../../assets/icons/${iconName}.${this.extension}`)
    }

    get classNames(): Record<string, boolean> {
        return {
            'icon': true,
            'icon--no-margin': this.noMargin,
            ['icon--size' + this.size]: this.size !== ""
        }
    }
}
