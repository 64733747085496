
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

/**
 * The Cluster composite allows to align elements that differ in length in a row and wrap them if needed.
 * It is capable of even spacing between elements.
 * A common use case is to display badges or labels.
 * It expects an element that contains a list of items to be displayed side by side.
 */
@Component
export default class Cluster extends Vue {
    /**
     * Specifies the HTML element to use to render the cluster.
     * @private
     */
    @Prop({type: String, default: "div"})
    private readonly as: string

    /**
     * Spacing between elements using a Unit Scale value or none.
     * @private
     */
    @Prop({type: String, default: "none"})
    private readonly spaceUnit: string

    /**
     * CSS value of justify-content property.
     * @private
     */
    @Prop({type: String, default: "start"})
    private readonly justify: string


    get classNames(): Record<string, boolean> {
        return {
            'cluster': true,
            [`cluster--space-unit${this.spaceUnit}`]: this.spaceUnit !== "none",
            [`cluster--justify-${this.justify}`]: true,
        }
    }
}
