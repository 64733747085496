import Vue from "vue"

import moment from 'moment'

/**
 * Returns the initials of a fullName
 */
const initials = function (s) {
    if(!s) return ''
    const parts = s.split(' ')

    if (parts.length === 1) {
        return parts[0].charAt(0)
    }

    const first = parts[0]
    const last = parts[parts.length - 1]

    return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase()
}
Vue.filter('initials', initials)

/**
 * Capitalizes a String
 */
const capitalize = function (value) {
    if (!value) return ''
    return value.toString().toLowerCase().charAt(0).toUpperCase() + value.slice(1)
}
Vue.filter('capitalize', capitalize)

/**
 * Converts a string in snake case to Title Case
 * @param value
 * @returns {string}
 */
const snakeToTitleCase = function (value) {
    if (!value) return ''
    return value.toString()
                .toLowerCase()
                .split('_')
                .map(capitalize)
                .join(" ")
}
Vue.filter('snakeToTitleCase', snakeToTitleCase)


Vue.filter('truncate', (v, limit) => v.length > limit ? v.substring(0, (limit - 3)) + '...' : v)

/**
 * Formats a boolean value for human representation
 */
const humanBoolean = function (value, trueValue = 'Yes', falseValue = 'No') {
    return value ? trueValue : falseValue
}
Vue.filter('humanBoolean', humanBoolean)

/**
 * Returns a default value when a given value is falsy
 * @param value
 * @param defaultValue
 * @returns {*}
 */
const defaultValue = function (value, defaultValue) {
    return value ? value : defaultValue
}
Vue.filter('defaultValue', defaultValue)

// Money
const formatMoney = function (value, currency) {
    // Add our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    })

    return formatter.format(value)
}
Vue.filter('money', formatMoney)

// HEX
/**
 * Converts a string to a RGB Unique hex color
 */
const colorizeName = function intToRGB(value){

    if(value === '') return '#18723E'

    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }

    const c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "#" + "00000".substring(0, 6 - c.length) + c;
}
Vue.filter('colorizeName', colorizeName)

// DateTime

/**
 * Formats a string date
 * @param value
 * @param format
 * @returns {string}
 */
const formatDate = function (value: string|Date, format = 'DD-MM-YYYY') {
    if(!value) return ''

    const momentValue = moment(String(value));


    if (format === 'fromNow') {
        return momentValue.fromNow()
    }

    return momentValue.format(format)
}
Vue.filter('formatDate', formatDate)


// Arrays
/**
 * Limits the number of elements in an array
 * @param value
 * @param limit
 * @returns {*}
 */
const limit = function (value, limit) {
    return value.slice(0, limit)
}
Vue.filter('limit', limit)