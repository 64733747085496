var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Box',[(_vm.label.type.name === 'string')?[_c('Input',{attrs:{"type":"text","label":_vm.capitalize(_vm.label.name),"hints":[{
          context: 'info',
          message: _vm.label.description,
        }]},on:{"input":_vm.handleInput}})]:_vm._e(),(_vm.label.type.name === 'number')?[_c('Input',{attrs:{"type":"number","label":_vm.capitalize(_vm.label.name),"min":_vm.label.type.min,"max":_vm.label.type.max,"hints":[{
          context: 'info',
          message: _vm.label.description,
        }]},on:{"input":_vm.handleInput}})]:_vm._e(),(_vm.label.type.name === 'enum')?[_c('Select',{attrs:{"label":_vm.capitalize(_vm.label.name),"options":_vm.enumOptions(),"value":null,"hints":[{
          context: 'info',
          message: _vm.label.description,
        }]},on:{"input":_vm.handleInput}})]:_vm._e(),(_vm.label.type.name === 'list')?[_c('MultiInput',{attrs:{"label":_vm.capitalize(_vm.label.name)},on:{"input":_vm.handleInput}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }