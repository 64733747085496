import Vue from 'vue'

import './registerServiceWorker'

// Plugins
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// Toasts
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

import router from '@/Core/router.ts'
import store from '@/Core/store.ts'

import './Core/VueFilters'
import './Core/VueDirectives'


import App from './App.vue'

Vue.use(FloatingVue)

Vue.use(Toast)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
