
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import Center from "@/AsicJungleAnalyticsUI/components/composites/Center.vue";

/**
 * Represents a Page that does not display the sidebar and takes up the full width and height of the browser.
 */
@Component({
  components: {Center, Stack, Box}
})
export default class FullPage extends Vue {
  @Prop({type: String, required: true})
  private readonly title: string

  created(): void {
    this.setTitle(this.title)
  }

  /**
   * Changes the title of the page.
   * @param title
   */
  setTitle(title: string): void {
    document.title = title + ' | Asic Jungle Analytics'
  }
}
