
import Vue from "vue";
import {Component} from "vue-property-decorator";

/**
 * StackSplit allows to push the next item to the end of the stack, if its height is longer than its content.
 */
@Component({})
export default class StackSplit extends Vue {

}
