
import Vue from "vue";
import Box from "../Box.vue";
import {Component, Prop} from "vue-property-decorator";

/**
 * Composite that centers itself within its parent.
 * Optionally it can also center its content within itself.
 * In CSS to center a div, it must have a width.
 * In the current case, the width is calculated using the --line-length property, which corresponds
 * to the maximum width in `ch`that some content can take.
 */
@Component({
    components: {Box}
})
export default class Center extends Vue {
    @Prop({type: String, default: ""})
    private readonly className: string

    /**
     * Allows specifying if the content should also be centered.
     * (this avoids using a center composite, within another center composite, to achieve the same result.)
     * @private
     */
    @Prop({type: Boolean, default: false})
    private readonly content: boolean;

    get classNames(): Record<string, boolean> {
        return {
            [this.className]: this.className !== "",
            'center': true,
            'center--content': this.content
        }
    }

}
