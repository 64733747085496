
import BaseInput from "@/AsicJungleAnalyticsUI/components/Form/BaseInput.vue";
import Icon from "@/AsicJungleAnalyticsUI/components/content/Icon.vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";

export interface SelectOption {
    text: string
    value: string

}

@Component({components: {Box, Icon, BaseInput}})
export default class Select extends BaseInput {
    @Prop({type: Array, required: true, default: () => []})
    private readonly options: SelectOption[]

    @Prop({required: true})
    private readonly value

    onInput(evt): void {
        const opt = this.options.filter((o: SelectOption) => o.text === evt.target.value)[0]
        this.$emit('input', opt.value)
    }
}
