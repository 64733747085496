import Vue from 'vue'
import Vuex from 'vuex'
import authStore from '@/Auth/store'

Vue.use(Vuex)

interface State {
    app: string
}

export default new Vuex.Store<State>({
    state: (): State => ({
        app: ""
    }),

    mutations: {
    },

    actions: {
    },

    getters: {
    },

    modules: {
        authStore,
    }
})
