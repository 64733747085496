
import {BaseComponent} from "@/AsicJungleAnalyticsUI/components/Component";
import {Component, Prop} from "vue-property-decorator";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import Navbar from "@/Navbar.vue";

/**
 * Represents a Webpage that displays the Navbar.
 */
@Component({
    components: {Navbar, Box, Stack}
})
export default class Page extends BaseComponent {
    @Prop({type: String, required: true})
    private readonly headerText: string

    @Prop({type: String, required: true})
    private readonly headerIcon: string

    @Prop({type: String, required: true})
    private readonly title: string

    created(): void {
        this.setTitle(this.title)
    }

    /**
     * Changes the title of the page.
     * @param title
     */
    setTitle(title: string): void {
        document.title = title + ' | Asic Jungle Analytics'
    }
}
