import {Prop} from "vue-property-decorator";
import Vue from "vue";

/**
 * Base class for components, so they all inherit the className property.
 */
export class BaseComponent extends Vue {
    
}

/**
 * Interface to be used by components to easily make them
 * aware of context colors. It does so by providing boolean properties for
 * context values.
 */
export abstract class ContextAwareComponent extends BaseComponent {
    /**
     * Indicates if this text should be using the primary color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly primary: boolean;

    /**
     * Indicates if this text should be using the secondary color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly secondary: boolean;

    /**
     * Indicates if this text should be using the tertiary color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly tertiary: boolean;

    /**
     * Indicates if this text should be using the info color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly info: boolean;

    /**
     * Indicates if this text should be using the success color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly success: boolean;

    /**
     * Indicates if this text should be using the warning color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly warning: boolean;

    /**
     * Indicates if this text should be using the danger color.
     * @public readonly
     */
    @Prop({type: Boolean, default: false})
    public readonly danger: boolean;
}