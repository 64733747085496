import User from "@/Auth/models/User";
import AuthApi from "@/Auth/AuthApi";
import {ApiResponse} from "@/Core/ApiClient";

interface State {
    user: User
}

export default {
    state: (): State => ({
        user: null,
    }),

    mutations: {
        SET_USER(state: State, user: User) {
            state.user = user
        },
    },

    actions: {
        login(context, {username, password}:{username: string, password: string}) {
            return AuthApi.login(username, password).then((r: ApiResponse<User>) => {
                context.commit("SET_USER", r.data)
                return r
            })
        },

        /**
         * Tries to authenticate a user from the cookie.
         * @param context
         * @param accessToken
         * @param refreshToken
         */
        authenticate(context) {
            return AuthApi.authenticate().then((r: ApiResponse<User>) => {
                context.commit("SET_USER", r.data)
                return r.data
            }).catch(() => {
                return null
            })
        },

        logout(context) {
            return AuthApi.logout().then((r: ApiResponse<null>) => {
                context.commit("SET_USER", null)
                return r
            })
        }
    },

    getters: {
        /**
         * Returns the current user.
         * @param state
         */
        currentUser: (state: State): User|null => state.user,

        /**
         * Indicates if the user was authenticated.
         * @param state
         */
        authenticated: (state: State): boolean => state.user != null,
    }
}