

import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";

/**
 * Represents a message providing additional information regarding the value in the input.
 * This can be used to display validation errors.
 */
export interface Hint {
    /**
     * Allows specifying a context value for the display of the message such as "danger", "warning, "info" etc.
     */
    context: string
    message: string
}

/**
 * Utility component used as a base for form inputs with support for a label and a list of hints.
 * It is intended for reuse to design custom components like inputs, selects etc.
 */
@Component({
    components: {Box, TextContent, Stack}
})
export default class BaseInput extends Vue {

    @Prop({type: String, required: false})
    private readonly label: string

    /**
     * Represents a message providing additional information regarding the value in the input.
     * This can be used to display validation errors.
     * @private
     */
    @Prop({type: Array, required: false, default: () => []})
    private readonly hints: Hint[]
}
