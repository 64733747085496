import Vue from "vue"

interface outsideClickableElement extends HTMLElement {
    clickOutsideEvent: (e: Event) => void
}
Vue.directive('click-outside', {

    bind: function (el, binding) {
        const cel = el as outsideClickableElement
        cel.clickOutsideEvent = (event: Event) => {
            if (el === event.target || el.contains(event.target)) {
                return;
            }

            binding.value()
        };
        document.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        const cel = el as outsideClickableElement
        // noinspection TypeScriptUnresolvedVariable
        document.body.removeEventListener('click', cel.clickOutsideEvent)
    },
})

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})