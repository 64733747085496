import {ApiClient, ApiResponse} from "@/Core/ApiClient";
import User from "@/Auth/models/User";


class AuthApi {
    login(username: string, password: string): Promise<ApiResponse<User>> {
        return ApiClient.post<User>({
            url: "/login",
            payload: {
                username,
                password
            }
        })
    }

    authenticate(): Promise<ApiResponse<User>> {
        return ApiClient.post<User>({
            url: "/auth",
            payload: {}
        })
    }

    logout(): Promise<ApiResponse<null>> {
        return ApiClient.post<null>({
            url: "/logout",
            payload: {}
        })
    }
}

const authApi = new AuthApi()
export default authApi