
import Vue from "vue";
import {Component} from "vue-property-decorator";
import Page from "@/AsicJungleAnalyticsUI/components/Page/Page.vue";
import Switcher from "@/AsicJungleAnalyticsUI/components/composites/Switcher.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Label from "@/AsicJungleAnalyticsUI/components/content/Label.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import StackSplit from "@/AsicJungleAnalyticsUI/components/composites/StackSplit.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Widget from "@/AsicJungleAnalyticsUI/components/Widget/Widget.vue";
import WidgetHeader from "@/AsicJungleAnalyticsUI/components/Widget/WidgetHeader.vue";
import WidgetBody from "@/AsicJungleAnalyticsUI/components/Widget/WidgetBody.vue";
import WidgetFooter from "@/AsicJungleAnalyticsUI/components/Widget/WidgetFooter.vue";
import Cluster from "@/AsicJungleAnalyticsUI/components/composites/Cluster.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import Form from "@/AsicJungleAnalyticsUI/components/Form/Form.vue";
import Input from "@/AsicJungleAnalyticsUI/components/Form/Input.vue";
import Grid from "@/AsicJungleAnalyticsUI/components/composites/Grid.vue";
import DataSourcesList from "@/Classification/components/DataSourcesList.vue";
import {DataSource} from "@/Classification/models/DataSource";
import DataSourceForm from "@/Classification/components/DataSourceForm.vue";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import ClassificationApi from "@/Classification/ClassificationApi";
import {ApiResponse} from "@/Core/ApiClient";

@Component({
  components: {
    DataSourceForm,
    DataSourcesList,
    Grid,
    Input,
    Form,
    Button,
    Cluster,
    WidgetFooter,
    WidgetBody,
    WidgetHeader, Widget, ColumnSpacer, StackSplit, Columns, Label, TextContent, Box, Stack, Switcher, Page}
})
export default class DataSourcePage extends Vue {

  private selectedDataSource: DataSource = null

  private dataSources: DataSource[] = []

  private labels: DataPointLabel[] = []

  created(): void {
    this.pollData()
    this.fetchLabels()
  }

  /**
   * Ensures data sources are sorted
   */
  get dataSourcesForDisplay(): DataSource[] {
    return this.dataSources.filter((ds) => {
      return ds.status === "ready"
    }).sort((a, b) => {
        if (a.date > b.date) {
          return 1
        }
        if (a.date < b.date) {
          return -1
        }
        return 0
      })
  }

  private readonly DATA_REFRESH_DELAY = 10 * 1000;

  pollData(): void {
    console.debug("Polling data...")
    this.fetchDataSources()
    setTimeout(() => {
      this.pollData()
    }, this.DATA_REFRESH_DELAY)
  }

  fetchLabels(): void {
    console.debug("Fetching labels...")
    ClassificationApi.getLabels().then((r: ApiResponse<DataPointLabel[]>) => {
      this.labels = r.data
    })
  }


  fetchDataSources(): void {
    console.debug("Fetching data sources...")
    ClassificationApi.getDataSources().then((r: ApiResponse<DataSource[]>) => {
      console.log(r)
      this.dataSources = r.data || []
    })
  }

  selectDataSource(ds: DataSource): void {
    this.selectedDataSource = ds
    this.fetchLabels()
  }

  handleSelectedDataSourceClassified(): void {
    this.selectedDataSource = this.dataSourcesForDisplay.length !== 0 ? this.dataSourcesForDisplay.at(this.dataSourcesForDisplay.length - 1) : null
    this.fetchDataSources()
    this.fetchLabels()
  }
}
