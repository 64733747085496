
import Vue from "vue";
import {Component} from "vue-property-decorator";
import SettingsApi from "@/Settings/SettingsApi";
import {ApiResponse} from "@/Core/ApiClient";
import Widget from "@/AsicJungleAnalyticsUI/components/Widget/Widget.vue";
import WidgetHeader from "@/AsicJungleAnalyticsUI/components/Widget/WidgetHeader.vue";
import WidgetBody from "@/AsicJungleAnalyticsUI/components/Widget/WidgetBody.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Page from "@/AsicJungleAnalyticsUI/components/Page/Page.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import LoadingIcon from "@/AsicJungleAnalyticsUI/components/content/LoadingIcon.vue";

@Component({
  components: {LoadingIcon, Button, Page, TextContent, Box, WidgetBody, WidgetHeader, Widget}
})
export default class SettingsPage extends Vue{

  private telegramAuthorized = false;
  private telegramAuthorizedCheckInProgress = false;

  private qrCode: string = null
  private qrCodeRequestInProgress = false;
  private waitingForQrCodeApprovalInProgress = false;

  created(): void {
    this.checkTelegramAuthorized()
  }

  checkTelegramAuthorized(): Promise<ApiResponse<boolean>> {
    this.telegramAuthorizedCheckInProgress = true
    return SettingsApi.checkTelegramAuthorized().then((r: ApiResponse<boolean>) => {
      this.telegramAuthorized = r.data
      return r
    }).catch((r: ApiResponse<null>) => {
      this.$toast.error(r.error.message)
      return r
    }).finally(() => {
      this.telegramAuthorizedCheckInProgress = false
    })
  }

  private requestQrCode(): void {
    this.qrCodeRequestInProgress = true
    SettingsApi.getTelegramQRCode().then((r: ApiResponse<string>) => {
      this.qrCode = r.data
      this.waitForApproval()
    }).catch((r: ApiResponse<null>) => {
      this.$toast.error(r.error.message)
    }).finally(() => {
      this.qrCodeRequestInProgress = false
    })
  }

  private waitForApproval() {
    this.waitingForQrCodeApprovalInProgress = true
    this.telegramAuthorizedCheckInProgress = true

    this.checkTelegramAuthorized().then((r: ApiResponse<boolean>) => {
      if (!r.data) {
        setTimeout(() => {
          this.waitForApproval()
        }, 1000 * 5)
      }
    }).finally(() => {
      this.waitingForQrCodeApprovalInProgress = false
    })
  }
}
