
import {Component} from "vue-property-decorator";
import Vue from "vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";

/**
 * Represents a Widget in the application.
 */
@Component({
    components: {Stack, Box}
})
export default class WidgetFooter extends Vue {

}
