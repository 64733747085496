import {RouteConfig} from "vue-router";
import LoginPage from "@/Auth/components/LoginPage.vue";

const routes: Array<RouteConfig> = [
    {
        name: "login",
        path: "/login",
        component: LoginPage,
        meta: {
            authenticate: false
        }
    }
]

export default routes