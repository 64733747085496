
import {Component, Prop} from "vue-property-decorator";
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";

/**
 * This component allows to define textual elements easily while providing context and styling options.
 */
@Component
export default class TextContent extends ContextAwareComponent {
    /**
     * Specifies the HTML element to use to render the cluster.
     * @private
     */
    @Prop({type: String, default: "span"})
    private readonly as: string

    @Prop({type: Boolean, default: false})
    private readonly strong: boolean

    @Prop({type: Boolean, default: false})
    private readonly alt: boolean;

    @Prop({type: Boolean, default: false})
    private readonly inverse: boolean;

    /**
     * Allows specifying that the component should be displayed as a block.
     * @private
     */
    @Prop({type: Boolean, default: false})
    private block: boolean

    get classNames(): Record<string, boolean> {
        return {
            'text' : true,
            'text--alt': this.alt,
            'text--inverse': this.inverse,
            'text--primary': this.primary,
            'text--secondary': this.secondary,
            'text--tertiary': this.tertiary,
            'text--info': this.info,
            'text--success': this.success,
            'text--warning': this.warning,
            'text--danger': this.danger,
            'text--strong': this.strong,
            'text--block': this.block,
        }
    }
}
