
import Component from "vue-class-component";
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";

@Component({})
export default class Badge extends ContextAwareComponent {
    get classNames(): Record<string, boolean> {
        return {
            'badge': true,
            'badge--primary': this.primary,
            'badge--secondary': this.secondary,
            'badge--tertiary': this.tertiary,
            'badge--info': this.info,
            'badge--success': this.success,
            'badge--warning': this.warning,
            'badge--danger': this.danger,
        }
    }
}
