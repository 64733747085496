
import {Component} from "vue-property-decorator";
import {ContextAwareComponent} from "@/AsicJungleAnalyticsUI/components/Component";

@Component
export default class Label extends ContextAwareComponent {
    get classNames(): Record<string, boolean> {
        return {
            'label': true,
            'label--primary': this.primary,
            'label--secondary': this.secondary,
            'label--tertiary': this.tertiary,
            'label--info': this.info,
            'label--success': this.success,
            'label--warning': this.warning,
            'label--danger': this.danger,
        }
    }
}
