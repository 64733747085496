
import Vue from "vue";
import {Component} from "vue-property-decorator";
import ClassificationApi from "@/Classification/ClassificationApi";
import {ApiResponse} from "@/Core/ApiClient";
import ClassifiedDataPoint from "@/Classification/models/ClassifiedDataPoint";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Grid from "@/AsicJungleAnalyticsUI/components/composites/Grid.vue";
import Page from "@/AsicJungleAnalyticsUI/components/Page/Page.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Label from "@/AsicJungleAnalyticsUI/components/content/Label.vue";
import {DataSource} from "@/Classification/models/DataSource";
import Cluster from "@/AsicJungleAnalyticsUI/components/composites/Cluster.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import StackSplit from "@/AsicJungleAnalyticsUI/components/composites/StackSplit.vue";
import LoadingIcon from "@/AsicJungleAnalyticsUI/components/content/LoadingIcon.vue";
import {saveAs} from "file-saver";

@Component({
  components: {
    LoadingIcon,
    StackSplit, Button, Columns, ColumnSpacer, Cluster, Label, TextContent, Stack, Page, Grid, Box}
})
export default class DataPointsPage extends Vue {

  private dataPoints: ClassifiedDataPoint[] = [];
  private dataPointsLoadingProgress = false

  private dataSources: DataSource[] = []
  private dataSourcesLoadingProgress = false

  private labels: DataPointLabel[] = []

  private selectedDataPoint: ClassifiedDataPoint = null
  private reclassificationRequestInProgress = false;

  private exportAsCsvOperationInProgress = false;


  get selectedDataPointDataSource(): DataSource {
    if (!this.selectedDataPoint) {
      return null
    }
    return this.dataSources.find((s: DataSource) => this.selectedDataPoint.dataSourceId === s.id)
  }

  created(): void {
    this.fetchDataPoints()
    this.fetchLabels()
    this.fetchDataSources()
  }

  fetchLabels(): void {
    ClassificationApi.getLabels().then((r: ApiResponse<DataPointLabel[]>) => {
      this.labels = r.data
    }).catch((err: ApiResponse<null>) => {
      this.$toast.error(err.error.message)
    })
  }

  fetchDataPoints(): void {
    this.dataPointsLoadingProgress = true
    ClassificationApi.getDataPoints().then((r: ApiResponse<ClassifiedDataPoint[]>) => {
      if (r.data == null) {
        return
      }

      this.dataPoints = r.data.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        }
      )
    }).catch((err: ApiResponse<null>) => {
      this.$toast.error(err.error.message)
    }).finally(() => {
      this.dataPointsLoadingProgress = false
    })
  }

  private fetchDataSources() {
    this.dataSourcesLoadingProgress = true
    ClassificationApi.getDataSources().then((r: ApiResponse<DataSource[]>) => {
      this.dataSources = r.data
    }).catch((err: ApiResponse<null>) => {
      this.$toast.error(err.error.message)
    }).finally(() => {
      this.dataSourcesLoadingProgress = false
    })
  }

  private handleSelection(p: ClassifiedDataPoint) {
    if (this.selectedDataPoint && this.selectedDataPoint.id === p.id) {
      this.selectedDataPoint = null
    }

    this.selectedDataPoint = p
  }

  private requestReclassification(p: ClassifiedDataPoint) {
    this.reclassificationRequestInProgress = true
    ClassificationApi.requestReclassification(p.id).then(() => {
      this.$toast.info("Reclassification requested successfully.")
      setTimeout(() => {
        this.fetchDataPoints()
      }, 800)
    }).catch((r: ApiResponse<null>) => {
      this.$toast.error(r.error.message)
    }).finally(() => {
      this.reclassificationRequestInProgress = false
    })
  }

  exportAsCsv(): void {
    this.exportAsCsvOperationInProgress = true
    ClassificationApi.exportDataPointsAsCsv().then((r: ApiResponse<string>) => {
      this.$toast.info("Data points exported successfully.")
      const blob = new Blob([r.data], {type: "text/csv;charset=utf-8"})
      saveAs(blob, "data_points.csv")
    }).catch((r: ApiResponse<null>) => {
      this.$toast.error(r.error.message)
    }).finally(() => {
      this.exportAsCsvOperationInProgress = false
    })
  }

  formatData(data: string): string {
    return data.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
