
import Vue from "vue";
import {Component} from "vue-property-decorator";
import Center from "@/AsicJungleAnalyticsUI/components/composites/Center.vue";
import Form from "@/AsicJungleAnalyticsUI/components/Form/Form.vue";
import Input from "@/AsicJungleAnalyticsUI/components/Form/Input.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import AuthApi from "@/Auth/AuthApi";
import {ApiResponse} from "@/Core/ApiClient";
import Notice from "@/AsicJungleAnalyticsUI/components/Notice/Notice.vue";
import LoadingIcon from "@/AsicJungleAnalyticsUI/components/content/LoadingIcon.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import FullPage from "@/AsicJungleAnalyticsUI/components/Page/FullPage.vue";
import Widget from "@/AsicJungleAnalyticsUI/components/Widget/Widget.vue";
import WidgetHeader from "@/AsicJungleAnalyticsUI/components/Widget/WidgetHeader.vue";
import WidgetBody from "@/AsicJungleAnalyticsUI/components/Widget/WidgetBody.vue";


@Component({
  components: {WidgetBody, WidgetHeader, Widget, FullPage, Box, LoadingIcon, Notice, Button, Input, Form, Center}
})
export default class LoginPage extends Vue {

  private errorMessage: string = null
  private operationInProgress = false
  private username: string = null
  private password: string = null

  get canSubmit() {
    return this.username != null && this.password != null
  }

  handleSubmit(): void {
    this.operationInProgress = true
    this.errorMessage = null

    this.$store.dispatch("login", {username: this.username, password: this.password}).then((r: ApiResponse<string>) => {
      this.$toast.info("Logged in successfully.")
      this.$router.push({name: "data-sources"})
    }).catch((r: ApiResponse<null>) => {
      this.errorMessage = r.error.message
      console.log(r.error)
    }).finally(() => {
      this.operationInProgress = false
    })
  }
}
