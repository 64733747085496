
import {Component, Vue} from "vue-property-decorator";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";

@Component({
  components: {Stack, Columns, ColumnSpacer, Box, Button}
})
export default class Navbar extends Vue {

  get currentUserIsAdmin(): boolean {
    return this.$store.getters.currentUser.role === "admin"
  }

  changeRoute(r: string) {
    this.$router.push({name: r})
  }

  logout(): void {
    this.$store.dispatch("logout").then(() => {
      this.$router.push({name: "login"})
    })
  }
}
