
import Vue from "vue";
import {Component} from "vue-property-decorator";
import Sidebar from "@/AsicJungleAnalyticsUI/components/Sidebar/Sidebar.vue";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import ContentArea from "@/AsicJungleAnalyticsUI/components/ContentArea.vue";
import User from "@/Auth/models/User";
import LoadingScreen from "@/LoadingScreen.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import Navbar from "@/Navbar.vue";

@Component({
    components: {Navbar, LoadingScreen, ContentArea, Box, Button, ColumnSpacer, Columns,}
})
export default class App extends Vue {
    /**
     * Indicates if the user was authenticated or not.
     */
    get authenticated(): boolean {
        return this.$store.getters["authenticated"]
    }

    get showLoadingScreen(): boolean {
      console.debug({authenticated: this.authenticated, route: this.$route.name})
      return !this.authenticated && this.$route.name !== "login"
    }

    /**
     * Returns the current user.
     */
    get currentUser(): User {
        return this.$store.getters["currentUser"]
    }
}
