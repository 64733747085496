import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route} from 'vue-router'
import store from './store'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css';
import authRoutes from "@/Auth/router"
import DataSourcesPage from "@/Classification/components/DataSourcesPage.vue";
import SettingsPage from "@/Settings/components/SettingsPage.vue";
import DataPointsPage from "@/Classification/components/DataPointsPage.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: '/',

    routes: [
        ...authRoutes,
        { name: 'data-sources', path: '/data-sources', alias: '/', component: DataSourcesPage},
        { name: 'data-points', path: '/data-points', alias: '/', component: DataPointsPage},
        { name: 'settings', path: '/settings', alias: '/', component: SettingsPage, meta: {adminOnly: true}},
    ]
})


NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
    if (to.name) {
        NProgress.start()
    }

    const mustAuthenticate = Object.prototype.hasOwnProperty.call(to.meta, 'authenticate') ? to.meta.authenticate : true;
    if (mustAuthenticate) {
        console.debug("must auth", to.name)
        const user = await store.dispatch('authenticate')
        if (!user) {
            console.debug("redirect to login")
            return next({name: "login"})
        }
        console.debug("authed!")
        const adminOnly = to.meta.adminOnly || false
        if (adminOnly && user.role !== "admin") {
            Vue.$toast.error("You do not have access to this page")
            return false
        }
    }
    next()
})

router.afterEach((to, from) => {
    NProgress.done()
})

export default router
