import { render, staticRenderFns } from "./DataSourcesList.vue?vue&type=template&id=67cd3514&scoped=true&"
import script from "./DataSourcesList.vue?vue&type=script&lang=ts&"
export * from "./DataSourcesList.vue?vue&type=script&lang=ts&"
import style0 from "./DataSourcesList.vue?vue&type=style&index=0&id=67cd3514&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cd3514",
  null
  
)

export default component.exports