import {ApiClient, ApiResponse} from "@/Core/ApiClient";
import {DataPoint} from "@/Classification/models/DataPoint";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import {DataSource} from "@/Classification/models/DataSource";

class SettingsApi {
    checkTelegramAuthorized(): Promise<ApiResponse<boolean>> {
        return ApiClient.get<boolean>({
            url: "/telegram/authorized",
            payload: {}
        })
    }

    getTelegramQRCode(): Promise<ApiResponse<string>> {
        return ApiClient.get<string>({
            url: "/telegram/qr-code",
            payload: {}
        })
    }
}

const api = new SettingsApi()
export default api