

import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class Columns extends Vue {
    /**
     * CSS value of justify-content property.
     * @private
     */
    @Prop({type: String, default: "start"})
    private readonly justify: string

    /**
     * CSS value of align-items property.
     * @private
     */
    @Prop({type: String, default: "start"})
    private readonly align: string

    /**
     * Allows ensuring children expand
     * @private
     */
    @Prop({type: Boolean, default: false})
    private expandChildren: boolean

    /**
     * Level in the unit scale for the space between elements in the columns.
     * @private
     */
    @Prop({type: String, default: "none"})
    private readonly spaceUnit: string

    @Prop({type: Boolean, default: false})
    private readonly debug: boolean

    get classNames(): Record<string, boolean> {
        return {
            'columns': true,
            [`columns--space-unit${this.spaceUnit}`]: this.spaceUnit !== "none",
            [`columns--justify-${this.justify}`]: true,
            [`columns--align-${this.align}`]: true,
            [`columns--expand`]: this.expandChildren,
            'columns--debug': this.debug
        }
    }
}
